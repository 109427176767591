import Img from 'gatsby-image'
import get from 'lodash/get'
import React, { useState } from 'react'
import Helmet from 'react-helmet'
import Footer from '../components/footer'
import JcWeek from '../components/jc-week'
import Layout from '../components/layout'
import Disqus from '../components/shared/Disqus'
import styles from './issues.module.css'

function Step({ title, img, desc }) {
  return (
    <div className={styles.step}>
      <h2 className={styles.stepTitle}>{title}</h2>
      <Img
        className={styles.stepIllo}
        fluid={img.childImageSharp.fluid}
        alt=""
      />
      <p className={styles.stepDesc}>{desc}</p>
    </div>
  )
}

export default function Issues(props) {
  const [email, setEmail] = useState('')

  function onInputEmail({ target: { defaultValue } }) {
    setEmail(defaultValue)
  }

  const weeks = get(props, 'data.allContentfulIssue.edges', []).map(
    edge => edge.node
  )
  const about = get(props, 'data.allContentfulAbout.edges')[0].node

  return (
    <Layout location={props.location}>
      <div className={styles.challenge}>
        <Helmet title="Journey East Zine">
          <meta property="og:title" content="Journey East Zine" />
          <meta property="og:type" content="website" />
          <meta
            property="og:description"
            content="Explore Chinese home cooking, one week at a time."
          />
          <meta
            property="og:image"
            content="https://journeyeast.co/static/bc74c1ea2de3a99286bd1cb5f6928bd1/88d05/illo1.png"
          />
          <meta property="og:image:width" content="800" />
          <meta property="og:image:height" content="636" />
          <meta property="og:url" content="https://journeyeast.co/zine/" />
        </Helmet>

        <section className={styles.flexColCenter}>
          {weeks.map(week => (
            <JcWeek week={week} key={`week-${week.number}`} />
          ))}
        </section>
      </div>
      <Disqus identifier="zine-homepage" title="Journey East Zine" />
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query Issues {
    backgrounds: allFile(
      filter: { sourceInstanceName: { eq: "backgrounds" } }
    ) {
      nodes {
        relativePath
        childImageSharp {
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    illo1: file(
      relativePath: { eq: "illo1.png" }
      sourceInstanceName: { eq: "challenge" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    illo2: file(
      relativePath: { eq: "illo2.png" }
      sourceInstanceName: { eq: "challenge" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    illo3: file(
      relativePath: { eq: "illo3.png" }
      sourceInstanceName: { eq: "challenge" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allContentfulAbout {
      edges {
        node {
          challenge {
            json
          }
          principles {
            json
          }
        }
      }
    }
    allContentfulIssue(sort: { fields: [number], order: DESC }) {
      edges {
        node {
          title
          number
          tagline
          issueDate
          recipes {
            title
          }
          content {
            json
          }
          illustration {
            fluid(maxWidth: 1048) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          region
        }
      }
    }
  }
`
